import { generateFormData } from "../../Utils/common";

class ProfileService {
  static getProfile() {
    return { url: `/me/profile`, method: "get" };
  }

  static update(data) {
    return { url: `/me/profile`, method: "put", data: generateFormData(data) };
  }
}

export default ProfileService;
