import moment from "moment";

export const truncater = (name = "", size) => {
  return name?.length > size ? `${name.substring(0, size)}...` : name;
};

export const getUserNameIntials = (name = "") => {
  let names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const getFullName = (fname, lname) => {
  return `${fname} ${lname || ""}`;
};

export const getPaginatedSerialNo = (pagination, currIdx) => {
  const { page, limit } = pagination;

  const baseSerialNo = page ? (page - 1) * limit : 0;
  const serialNo = baseSerialNo + (currIdx + 1); //Assuming Idx start with 0

  return `${serialNo < 10 ? "0" : ""}${serialNo}`;
};

// string2 can be array or string only
export const concatStrings = (string1, string2, seperator = " ") => {
  let string = "";

  if (string1) {
    string = string1;
  }

  if (Array.isArray(string2)) {
    string2.forEach((str) => {
      if (str) {
        string += string ? `${seperator + str}` : str;
      }
    });
  } else if (typeof string2 === "string") {
    string += string ? `${seperator + string2}` : string2;
  }

  return string;
};

export const appFormatDateTime = (dateTime, onlyDate = true) =>
  dateTime ? moment(dateTime).format(`DD MMM YYYY${onlyDate ? "" : " hh:mm A"}`) : "";

export const payloadFormatDate = (date) => (date ? moment(date).format("YYYY-MM-DD") : "");

// Payload with file need to convert in JS FormData object
export const generateFormData = (data) => {
  const formData = new FormData();

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const value = data[key];

      formData.append(key, value);
    }
  }

  return formData;
};

export const isValidUrl = (urlString) => {
  let url;

  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};
