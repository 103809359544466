import { useToast } from "@chakra-ui/toast";
import { useEffect, useRef } from "react";
import axios from "Utils/axios";

const ApiCancelErrCode = {
  code: "API_CANCELED",
  message: "Programatically canceled API request",
};

function useApi() {
  const toast = useToast();
  const controllerRef = useRef(new AbortController());

  const callApi = (
    urlData,
    handleSuccess, // type: function
    showSuccesToast = false, // type: boolean
    handleError, // type: function, For custom error message, this function should return error msg else return false.
    /* (Arrorw function should be like this,() => { Code... } not like this () => some code)*/
    showErrorToast = true, // type: boolean
    handleFinal, // type: function
  ) => {
    const { url, method, data, params } = urlData;

    axios
      .request({
        data,
        params,
        signal: controllerRef.current.signal,
        method,
        url,
      })
      .then(({ data }) => {
        showSuccesToast &&
          toast({
            title: data.message || "Success",
            status: "success",
            isClosable: true,
            duration: 5000,
            position: "top-right",
          });
        handleSuccess && handleSuccess(data);
      })
      .catch((e) => {
        console.log(e);
        if (e.code == "ERR_CANCELED") {
          const { reason } = controllerRef.current.signal;

          // To skip toast of programtically canceled API requests error msg
          if (reason?.code == ApiCancelErrCode.code) {
            console.log(ApiCancelErrCode);
            return;
          }
        }

        // In case of custom error message, handleError function should return custom error msg text
        let tempMsg = handleError && handleError(e);
        let errMsg = typeof tempMsg === "string" ? tempMsg : e.message;

        showErrorToast &&
          toast({
            title: errMsg || "Please try again!",
            status: "error",
            isClosable: true,
            duration: 5000,
            position: "top-right",
          });
      })
      .finally(() => {
        handleFinal && handleFinal();
      });
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      controllerRef.current.abort(ApiCancelErrCode);
    };
  }, []);

  return callApi;
}

export default useApi;
