import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { auth, emailProvider, googleProvider } from "../firebase";
import Loader from "../Components/Loader";
import useApi from "../Hooks/useApi";
import { Menus, Permissions } from "../Utils/constants";
import _ from "lodash";
import ProfileService from "../Services/Profile/Profile";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(); // Firebase logged in user data. Used for confirming user sucessfully logged in from firebase
  const [userId, setUserId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [userDetails, setUserDetails] = useState({}); // Store all user details in this state & use this variable to access user's basic details like name, imageUrl, etc.
  const [permissions, setPermissions] = useState({});
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoginFailed, setIsLoginFailed] = useState(false); // This state is use to handle error from the get profile API when user sucessfully logged from firebase but not from API server
  const [redirectPage, setRedirectPage] = useState(""); // Used to redirect user requested page after successful login
  const [isManualLogout, setIsManualLogout] = useState(false); // Used to set redirection page if user manually logged out

  const apiHelper = useApi();

  const logout = (manuallyLoggedOut = true) => {
    setIsManualLogout(manuallyLoggedOut);

    return auth.signOut();
  };

  const getRolePermissions = (allowedPermissions) => {
    const clonedPermissions = _.cloneDeep(Permissions);
    const modules = Object.keys(allowedPermissions);

    modules.forEach((module) => {
      const modulePermissions = allowedPermissions[module];

      modulePermissions.forEach((p) => {
        clonedPermissions[module][p] = true;
      });
    });

    return clonedPermissions;
  };

  const getAllowedMenus = (allowedPermissions) => {
    const tempMenu = [];

    Menus.forEach((m) => {
      const { module, permission } = m.permissionKey;

      if (allowedPermissions[module]?.includes(permission)) {
        tempMenu.push(m);
      }
    });

    return tempMenu;
  };

  function getProfile() {
    apiHelper(
      ProfileService.getProfile(),
      ({ data }) => {
        const { id, role } = data.user;

        setPermissions(getRolePermissions(data.permissions));
        setMenus(getAllowedMenus(data.permissions));

        setUserDetails(data.user);
        setRoleId(role.id);
        setUserId(id);
      },
      false,
      () => {
        setIsLoginFailed(true);
        logout(false);
      },
      true,
      null,
    );
  }

  const resetValue = () => {
    setUserId(null);
    setRoleId();
    setUserDetails({});
    setIsLoginFailed(false);
    setPermissions({});
    setMenus([]);
    setLoading(false);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      user ? getProfile() : resetValue();
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentUser && userId && setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const value = {
    currentUser,
    userId,
    userDetails, // When user profile data updated, this will be used to update user's data all over the project like name, imageUrl...
    roleId,
    setUserDetails,
    logout,
    isLoginFailed,
    isManualLogout,
    redirectPage,
    setRedirectPage,
    permissions,
    menus,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Loader height="100vh" spinnerSize="xl" /> : children}
    </AuthContext.Provider>
  );
}
AuthProvider.propTypes = {
  children: PropTypes.node,
};
