import React, { Suspense, lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Loader from "../Components/Loader";
import { useAuth } from "../Contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

// Public Page Components
const Login = lazy(() => import("Pages/Auth/Login"));
const ForgotPassword = lazy(() => import("Pages/Auth/ForgotPassword"));

const ResetPassword = lazy(() => import("Pages/Auth/Email/ResetPassword"));
const AdminInvite = lazy(() => import("Pages/Auth/Email/AdminInvite"));

// Private page components
const Profile = lazy(() => import("Pages/Profile/Profile"));
const Users = lazy(() => import("Pages/Users/Users"));
const Manufacturers = lazy(() => import("Pages/Manufacturers/Manufacturers"));
const Products = lazy(() => import("Pages/Products/Products"));
const Customers = lazy(() => import("Pages/Customers/Customers"));
const DailyUpdates = lazy(() => import("Pages/DailyUpdates/DailyUpdates"));

function Routes() {
  const {
    permissions: { Customer, DailyUpdate, Manufacturer, Product, User },
  } = useAuth();

  return (
    <Suspense fallback={<Loader height="80vh" spinnerSize="lg" />}>
      <Switch>
        {/* Use PrivateRoute for pages which is only accessible for loggedin user */}
        {/* Use PublicRoute for pages which is only accessible for non-loggedin user */}
        {/* Use Route for pages which can be accessible for all */}

        <PublicRoute exact path="/login" title="Login" component={Login} />
        <PublicRoute
          exact
          path="/forgot-password"
          title="Forgot password"
          component={ForgotPassword}
        />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exacr path="/account-activated" component={AdminInvite} />

        <PrivateRoute exact path="/profile" isAllowed title="Profile" component={Profile} />
        <PrivateRoute
          exact
          path="/users"
          isAllowed={!!User?.viewAll}
          title="Users"
          component={Users}
        />
        <PrivateRoute
          exact
          path="/manufacturers"
          isAllowed={!!Manufacturer?.viewAll}
          title="Manufacturers"
          component={Manufacturers}
        />
        <PrivateRoute
          exact
          path="/products"
          isAllowed={!!Product?.viewAll}
          title="Products"
          component={Products}
        />
        <PrivateRoute
          exact
          path="/customers"
          isAllowed={!!Customer?.viewAll}
          title="Customers"
          component={Customers}
        />
        <PrivateRoute
          exact
          path="/daily-updates"
          isAllowed={!!DailyUpdate?.viewAll}
          title="Daily Updates"
          component={DailyUpdates}
        />

        {/* Default route */}
        <PrivateRoute path="*" isAllowed component={() => <Redirect to={`/customers`} />} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
