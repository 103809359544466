import axios from "axios";
import { auth } from "../firebase";
import { AccountErrCodes } from "./ResCode";

const { INVALID_TOKEN_FIREBASE, ACCOUNT_INACTIVE, USER_NOT_FOUND_DB } = AccountErrCodes;

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  async (config) => {
    config["device-platform"] = "browser";

    if (auth.currentUser) {
      const token = await auth.currentUser.getIdToken();
      config.headers.authorization = `Bearer ${token}`;
    } else if (sessionStorage.getItem("tempToken")) {
      const token = sessionStorage.getItem("tempToken");
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code == "ERR_CANCELED") return Promise.reject(error);

    if (!error.response || !error.response.data) {
      return Promise.reject({
        data: null,
        message: "Please try again.",
      });
    }

    // Handle logout on resCodes
    const { resCode } = error.response.data;

    if (
      resCode &&
      [INVALID_TOKEN_FIREBASE, ACCOUNT_INACTIVE, USER_NOT_FOUND_DB].includes(resCode)
    ) {
      auth.signOut();
    }

    return Promise.reject(error.response.data);
  },
);

export default instance;
