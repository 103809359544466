import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { NavLink, useHistory } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

import AppIcon from "Assets/icons/sample.png";
import { useAuth } from "../Contexts/AuthContext";
import ProfileImageBox from "./ProfileImageBox";

const MenuListItem = ({ path, label }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <NavLink
      exact
      to={path}
      style={{
        display: "block",
        padding: "8px 16px",
        fontSize: "16px",
        backgroundColor: hovered ? "#9494941a" : "inherit",
      }}
      activeStyle={{
        backgroundColor: "#9494942a",
        fontWeight: "bold",
        color: "#00bfff",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {label}
    </NavLink>
  );
};
MenuListItem.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const AppNavLink = ({ to, submenu, label, ...props }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  return (
    <ListItem
      float="left"
      onMouseEnter={() => submenu && setShowSubmenu(true)}
      onMouseLeave={() => submenu && setShowSubmenu(false)}
    >
      <NavLink
        exact={!submenu}
        to={to}
        style={{
          display: "block",
          padding: "0 16px",
          lineHeight: "48px",
          fontSize: "16px",
          pointerEvents: !submenu ? "initial" : "none",
        }}
        activeStyle={{
          borderBottom: "3px solid #00bfff",
          fontWeight: "bold",
          color: "#00bfff",
          lineHeight: "45px",
        }}
        {...props}
      >
        <Flex alignItems="center" className="space-x-1">
          <Text>{label}</Text>
          {submenu ? <>{showSubmenu ? <AiFillCaretUp /> : <AiFillCaretDown />}</> : null}
        </Flex>
      </NavLink>
      {submenu ? (
        <UnorderedList
          listStyleType="none"
          position="absolute"
          top="48px"
          zIndex={455}
          bg="#fff"
          m={0}
          p="2px"
          display={showSubmenu ? "block" : "none"}
          boxShadow="0 0 5px 1px #4a4a4a1a"
        >
          {submenu.map((sm) => (
            <ListItem key={sm.id} onClick={() => setShowSubmenu(false)}>
              <MenuListItem path={sm.path} label={sm.label} />
            </ListItem>
          ))}
        </UnorderedList>
      ) : null}
    </ListItem>
  );
};

function Navbar() {
  const { userId, logout, userDetails, menus } = useAuth();
  const history = useHistory();

  return (
    <Flex
      height={12}
      alignItems="center"
      px={10}
      borderBottom="1px solid #e8e8e8"
      color="#949494"
      bg="#ffffff"
    >
      <Flex alignItems="center" className="space-x-2" mr={7}>
        <img src={AppIcon} className="h-8" />
        <Text fontWeight="bold">{process.env.REACT_APP_WEBSITE_NAME}</Text>
      </Flex>

      {userId ? (
        <>
          <UnorderedList listStyleType="none" m={0} p={0} overflow="hidden" className="space-x-2">
            {menus.map((m) => {
              const { submenu } = m;

              return <AppNavLink key={m.id} to={m.path} label={m.label} submenu={submenu} />;
            })}
          </UnorderedList>
          <Flex justifyContent="flex-end" flex={1}>
            <Menu>
              <MenuButton className="focus:outline-none">
                <Box className="flex items-center space-x-3">
                  <ProfileImageBox imageUrl={userDetails.imageUrl} name={userDetails.name} />
                  <Box className="flex space-x-1 items-center">
                    <h4>{userDetails.name}</h4>
                    <IoIosArrowDown size={21} className="opacity-30" />
                  </Box>
                </Box>
              </MenuButton>
              <MenuList>
                <MenuItem className="focus:outline-none" onClick={() => history.push("/profile")}>
                  View Profile
                </MenuItem>
                <MenuItem className="focus:outline-none" onClick={() => logout()}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </>
      ) : null}
    </Flex>
  );
}

AppNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  submenu: PropTypes.array,
};

export default Navbar;
