export const Roles = {
  ADMIN: {
    id: 100,
    name: "Admin",
  },
  OPERATIONS_HEAD: {
    id: 200,
    name: "Operation Head",
    defaultPagePath: "",
  },
  OPERATIONS_EXECUTIVE: {
    id: 300,
    name: "Operations Executive",
    defaultPagePath: "",
  },
  SALES_HEAD: {
    id: 400,
    name: "Sales head",
    defaultPagePath: "",
  },
  SALES_PERSON: {
    id: 500,
    name: "Sales Person",
  },
  SALES_COORDINATOR: {
    id: 600,
    name: "Sales Co-ordinator",
  },
};

export const Permissions = {
  Customer: {
    viewAll: false,
    create: false,
    update: false,
    updateStatus: false,
    updateWorkingStatus: false,
  },
  CustomerBranch: {
    viewAll: false,
    create: false,
    update: false,
    delete: false,
  },
  CustomerContact: {
    viewAll: false,
    create: false,
    update: false,
    delete: false,
  },
  CustomerReference: {
    viewAll: false,
    create: false,
    delete: false,
  },
  CustomerProduct: {
    viewAll: false,
    assign: false,
    unassign: false,
    setRegular: false,
    removeRegular: false,
  },
  CustomerUser: {
    viewAll: false,
    assign: false,
    unassign: false,
  },
  Manufacturer: {
    viewAll: false,
    create: false,
    update: false,
    updateStatus: false,
  },
  ManufacturerProduct: {
    viewAll: false,
    assign: false,
    unassign: false,
  },
  Product: {
    viewAll: false,
    create: false,
    update: false,
    updateStatus: false,
  },
  ProductCustomer: {
    viewAll: false,
    assign: false,
    unassign: false,
  },
  ProductManufacturer: {
    viewAll: false,
    assign: false,
    unassign: false,
  },
  DailyUpdate: {
    viewAll: false,
    create: false,
  },
  User: {
    viewAll: false,
    create: false,
    update: false,
    updateStatus: false,
    resetPassword: false,
    resendInvite: false,
    delete: false,
  },
  UserAddress: {
    viewAll: false,
    create: false,
    update: false,
    delete: false,
  },
  UserContact: {
    viewAll: false,
    create: false,
    update: false,
    delete: false,
  },
  UserDocument: {
    viewAll: false,
    create: false,
    update: false,
    delete: false,
  },
  UserIncrement: {
    viewAll: false,
    create: false,
  },
  UserCustomer: {
    viewAll: false,
    assign: false,
    unassign: false,
  },
};

export const Menus = [
  {
    id: "customers",
    path: "/customers",
    label: "Customers",
    permissionKey: { module: "Customer", permission: "viewAll" },
  },
  {
    id: "dailyUpdates",
    path: "/daily-updates",
    label: "Daily Updates",
    permissionKey: { module: "DailyUpdate", permission: "viewAll" },
  },
  {
    id: "manufacturers",
    path: "/manufacturers",
    label: "Manufacturers",
    permissionKey: { module: "Manufacturer", permission: "viewAll" },
  },
  {
    id: "products",
    path: "/products",
    label: "Products",
    permissionKey: { module: "Product", permission: "viewAll" },
  },
  {
    id: "users",
    path: "/users",
    label: "Users",
    permissionKey: { module: "User", permission: "viewAll" },
  },
];

export const UserStatus = {
  USER_INVITED: { id: 100, name: "Invited" },
  USER_ACTIVE: { id: 200, name: "Active" },
  USER_INACTIVE: { id: 300, name: "Inactive" },
};

export const AddressTypes = [
  { id: 100, name: "Local" },
  { id: 200, name: "Permanent" },
];

export const ManufacturerStatus = {
  MFRR_ACTIVE: { id: 100, name: "Active" },
  MFRR_INACTIVE: { id: 200, name: "Inactive" },
};

export const ProductStatus = {
  PRODUCT_ACTIVE: { id: 100, name: "Active" },
  PRODUCT_INACTIVE: { id: 200, name: "Inactive" },
};

export const CustomerStatus = {
  CUSTOMER_ACTIVE: { id: 100, name: "Active" },
  CUSTOMER_INACTIVE: { id: 200, name: "Inactive" },
};
